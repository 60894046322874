<template>
    <div ng-if="errors.length">
        <div class="alert alert-danger alert-dismissible" style="display: block;"
             v-for="error in errors" :key="error.datetime.timestamp">
            <button class="close" aria-hidden="true" @click="hide(error)" style="color: #fff; opacity: .9;">×</button>
            <h5><i class="icon fas fa-exclamation-triangle"></i> {{ t('general.error_title') }} </h5>
            {{ tOrTake(error.message) }}
        </div>
    </div>
</template>

<script>

    import {mapGetters} from 'vuex';
    import umsMixin from '@/mixins/UMS';
    export default {
        mixins  : [umsMixin],
        computed: {
            ...mapGetters(['errors'])
        },
        methods : {
            hide(error) {
                this.$store.dispatch('hideError', error);
            }
        }
    }
</script>
