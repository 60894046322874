import initialStates from "./initial-states";

export default {
    namespaced: true,
    state     : {
        files   : initialStates.admin.files(),
        requests: initialStates.admin.requests(),
    },
    mutations : {
        CLEAR_ALL(state){
            state.files = initialStates.admin.files();
        },
        setFiles(state, {files}) {
            state.files = files;
        },
        setRequests(state, {accounts, resources}){
            state.requests.accounts = accounts;
            state.requests.resources = resources;
        }
    },
    actions   : {
        loadFiles({commit}){
            return window.UMS.api.get('file', 'list')
                .then(({data}) => commit('setFiles', data));
        },
        loadRequests({commit}){
            return window.UMS.api.get('admin', 'requests')
                .then(({data}) => commit('setRequests', data));
        },
        loadRequestForm({commit}, {type, name}){
            return window.UMS.api.get('resource-admin', 'form', {type, name});
        },
        createResource({commit}, data){
            return window.UMS.api.post('resource-admin', 'create', data);
        },
        extendResource({commit}, data){
            return window.UMS.api.post('resource-admin', 'extend', data);
        },
        declineResourceRequest({commit}, data){
            return window.UMS.api.post('resource-admin', 'decline-request', data);
        },
        saveResourceAdminInfo({commit}, data){
            return window.UMS.api.post('resource-admin', 'admin-info', data);
        },
        loadAccountRequestForm({commit}, {order_number}){
            return window.UMS.api.get('account-admin', 'form', {order_number});
        },
        declineAccountRequest({commit}, data){
            return window.UMS.api.post('account-admin', 'decline-request', data);
        },
        createAccount({commit}, data){
            return window.UMS.api.post('account-admin', 'create', data);
        },
        extendAccount({commit}, data){
            return window.UMS.api.post('account-admin', 'extend', data);
        },
        deleteFile({commit}, {uuid}){
            return window.UMS.api.post('file', 'delete', {uuid})
                .then(({data}) => commit('setFiles', data));
        },
        loadAccountApplications({commit}){
            return window.UMS.api.get('account-admin', 'loadAccountApplications', {});
        },
        showAccountApplication({commit}, {order_number}){
            return window.UMS.api.get('account-admin', 'showAccountApplication', {order_number});
        },
    },
    getters   : {
        files(state){
            return state.files;
        },
        accountRequests(state){
            return state.requests.accounts;
        },
        resourceRequests(state){
            return state.requests.resources;
        },
    }
};