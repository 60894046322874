<template>
    <div>
        <loading-overlay></loading-overlay>
        <div class="single-box">
            <div class="col text-center float-right">
                <img src="/img/logo.svg" class="logo" :alt="t('general.university_greifswald')" :title="t('general.university_greifswald')"/>
            </div>
            <div class="login-logo">
                <a href="/" v-html="t('general.title')"></a>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    {{ t('general.language_change') }}:
                    <a href="#" @click.prevent="changeLanguage(t('general.lang_opposite_key'))">
                       {{ t('general.lang_opposite') }}</a>
                </div>
            </div>
        </div>
        <global-errors v-if="withGlobalErrors"></global-errors>
        <slot></slot>
    </div>
</template>

<script>
    import umsMixin from '../mixins/UMS';
    import componentResizeMixin from '../mixins/ComponentResize';
    import GlobalErrors from './GlobalErrors.vue';
    import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
    export default{
        components: {GlobalErrors, LoadingOverlay},
        props     : {
            withGlobalErrors: Boolean
        },
        computed  : {
            language(){
                return this.t("general.lang_key") || 'de';
            }
        },
        mixins    : [umsMixin, componentResizeMixin],
        methods   : {
            changeLanguage(language){

                this.$store.dispatch('language/change', {language});
            }
        }
    };
</script>
