export default {
    account  : {
        account(){
            return {acl: {}, email_quota: {}, nextcloud_quota: {}, superiors_fullname: {}, affiliation_status: [], mails: [], resetMail: {}};
        },
        accountPromise: () => null,
        agreements    : () => [],
    },
    admin    : {
        files: () => [],
        requests(){
            return {accounts: [], resources: []};
        },
    },
    base     : {
        errors : () => [],
        loading: () => 0,
        status(){
            return {ceo: 0, requests: 0};
        },
    },
    editor   : {
        phrasebook(){
            return {de: {}, en: {}};
        },
    }
    ,
    language : {
        dict(){
            return {de: {}, en: {}};
        },
    }
    ,
    resources: {
        resources: () => [],
        ceo      : () => [],
    }
}
;