<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button" :title="t('menu.hamburger_menu_info')"><i class="fas fa-bars"></i></a>
            </li>
            <!--<li class="nav-item d-none d-sm-inline-block">-->
                <!--<a href="/" class="nav-link">Home</a>-->
            <!--</li>-->
            <!--<li class="nav-item d-none d-sm-inline-block">-->
                <!--<a href="/" class="logo">-->
                    <!--<span class="logo-mini"></span>-->
                    <!--<span class="logo-lg">Accountverwaltung</span>-->
                <!--</a>-->
            <!--</li>-->
            <!--TODO @KB add Contact-->
        </ul>

        <!-- SEARCH FORM -->

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">

            <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent="changeLanguage(t('general.lang_opposite_key'))">
                    {{ t('general.lang_opposite') }}
                </a>
            </li>
            <!-- User Account Dropdown Menu -->
            <li class="nav-item">
                <a href="/account" class="nav-link">
                    <!-- The user image in the navbar-->
                    <img :src="avatar" class="mr-3 rounded-circle" width="25px" height="25px" alt="User Avatar">
                    <!-- d-none d-sm-inline hides the username on small devices so only the image appears. -->
                    <span class="d-none d-sm-inline">{{ user.cn }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a href="#" data-toggle="control-sidebar" @click.prevent="$emit('logout')"
                   :title="t('menu.logout')" class="nav-link">
                    <i class="fas fa-sign-out-alt"></i>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
    import umsMixin from '../mixins/UMS'
    export default {
        name   : 'Navigation',
        mixins : [umsMixin],
        methods: {
            changeLanguage(language){
                this.$store.dispatch('language/change', {language});
            }
        }
    }
</script>