<template>
    <li class="nav-item" v-on:click="clickMenuRoot">
        <a href="#" class="nav-link">
            <i :class="['nav-icon fas fa-fw', icon, textColor]"></i>
            <p>
                {{ text }}
                <i class="right fas fa-angle-left"></i>
            </p>
        </a>
        <ul class="nav nav-treeview autoHeight">
            <slot></slot>
        </ul>
    </li>
</template>
<script>
    export default {
        props   : {
            route: {type: [String, Object]},
            icon : String,
            text : String,
            color: String,
            label: String,
            exact: Boolean,
            event: {type: String, default: 'click'}
        },
        computed: {
            bgColor(){
                if (!this.color) {
                    return '';
                }
                return `bg-${this.color}`;
            },
            textColor(){
                if (!this.color) {
                    return '';
                }
                return `text-${this.color}`;
            },
        },
        methods : {
            clickMenuRoot() {
                this.workAroundCorrectClosingMenu();
            },
            workAroundCorrectClosingMenu() {
                // workAroundCorrectClosingMenu ist notwendig wenn:
                // - ein Refresh/Aktualiserung erfolgt ist, dann erscheint diese Element in der Nähe des Footers: <div id="sidebar-overlay"></div>
                // - ein direktes Login nach einem Logout erfolgt, das wird mit dem Hilfskonstrukt kenntlich gemacht: $("body").attr("loading", "2");
                // Achtung: Der workaround funktioniert nur, wenn das Initialisieren des Treeviews bei mehrmaligem Login -> Logout -> Login verhindert wird, siehe SidebarMenu.vue initTreeViewWorkAround()
                // AdminLTE Bug in Version 3.1 in vuejs 2.6 Umgebung !!!
                // Ansonsten können Menüs nach einem Refresh nicht mehr zugeklappt werden.

                if(($("#sidebar-overlay").length > 0) || ($("body").attr("loading") == "2")){
                    $("li").removeClass("menu-is-open menu-open menu-is-opening");
//                    console.log("remove class menu-is-open menu-open menu-is-opening");
                }
            },
        }
    }
</script>

<style scoped>
    .nav-link{
        display: flex !important;
        align-items: baseline;
        justify-content: flex-start;
    }

    .nav-link > p{
        margin-left: 6px;
        white-space: normal;
    }

    .autoHeight {
        /* Workaround For Adminlte 3.1 + Vuejs 2.6 */
        height: auto !important;
    }

    /* Untermenüs farbig hervorheben */
    ul.nav-treeview {
        background-color: #6b6c6c85!important;
    }
</style>