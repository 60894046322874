import initialStates from "./initial-states";

export default {
    state    : {
        loading: initialStates.base.loading(),
        errors : initialStates.base.errors(),
        status : initialStates.base.status(),
    },
    mutations: {
        CLEAR_ALL(state){
            state.loading = initialStates.base.loading();
            state.errors = initialStates.base.errors();
            state.status = initialStates.base.status();
        },
        setLoading(state, payload){
            state.loading = state.loading + (payload ? 1 : -1);
        },
        setErrors(state, errors){
            state.errors = errors;
        },
    },
    actions  : {
        addError({state, commit}, error){
            let errors = state.errors;
            if (typeof error === 'string') {
                error = {message: error};
            }
            error.datetime = new Date();
            errors.push(error);
            commit('setErrors', errors);
        },
        hideError({state, commit}, error){
            let errors = state.errors;
            let newErrors = [];
            newErrors = errors.filter(err => err !== error);
            commit('setErrors', newErrors);
        },
        updateStatus({state}, error){
          window.UMS.api.get('app', 'status', {}, {loading_status: false})
              .then(({data}) => state.status = data)
              .catch(error => error);
        },
    },
    getters  : {
        loading(state){
            return state.loading > 0;
        },
        errors(state){
            return state.errors;
        },
        status(state) {
            return state.status;
        }        
    }
};