export default {
    methods: {
        orderBy(array, property, reverse = false){
            if (typeof array === 'object'){
                array = Object.values(array);
            }
            return array.sort((a, b) => {
                // if the property to be sorted does not exist in the object a or b
                if (!(property in a) || (!(property in b))){
                    // console.log("interner Fehler: sortierende Eigenschaft " + property + " existiert nicht im Objekt a oder b!");
                    return 0;
                }
                if (typeof a[property] === 'number' && typeof b[property] === 'number') {
                    // If both compared fields are native numbers
                    return a[property] < b[property] ? -1 : (a[property] > b[property] ? 1 : 0)
                } else {
                    a = a[property].toLowerCase();
                    b = b[property].toLowerCase();
                    if (a === b) return 0;
                    if (!reverse) {
                        // ascending
                        return (a < b) ? -1 : 1;
                    }
                    // descending
                    return (a > b) ? -1 : 1;
                }
            });
        },

        chunk(array, size){
            let chunks = [];
            let i, j;
            for (i = 0, j = array.length; i < j; i += size) {
                chunks.push(array.slice(i, i + size));
            }
            return chunks
        }
    }
}