import Vue from "vue";
import moment from "moment";

import filters from "./filters";
import router from "@/router";
import store from "@/store";
import Toasted from "vue-toasted";

import AppView from "@/components/App.vue";
import Alert from "@/components/ui/Alert.vue";
import { BTable } from 'bootstrap-vue';
import Card from "@/components/ui/Card.vue";
import Modal from "@/components/ui/Modal.vue";

window.storage = store;
window.router = router;
window.moment = moment;

Vue.filter('count', filters.count);
Vue.filter('int', filters.int);
Vue.filter('upper', filters.upper);
Vue.filter('lower', filters.lower);
Vue.filter('ucfirst', filters.ucfirst);
Vue.filter('date', filters.date);
Vue.filter('datetime', filters.datetime);
Vue.filter('json', filters.json);
Vue.filter('ip', filters.ip);
Vue.filter('mac', filters.mac);


Vue.component('alert', Alert);
Vue.component('card', Card);
Vue.component('modal', Modal);
Vue.component('b-table', BTable);
Vue.use(Toasted, {
    duration: 4000,
    position: 'top-center',
    iconPack: 'fontawesome',
    theme   : 'ums',
});

new Vue({
    router,
    store,
    beforeCreate(){
        this.$store.dispatch('language/load');
    },
    el    : '#root',
    render: h => h(AppView),
});