<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">

        <!-- Brand Logo -->
        <a href="/" class="brand-link text-center">
            <!--<img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image rounded-circle elevation-3"-->
            <!--style="opacity: .8">-->
            <span class="brand-text font-weight-light"> {{ t('login.header') }} </span>
        </a>

        <!-- Sidebar -->
        <div class="sidebar">

            <!-- Sidebar Menu -->
            <sidebar-menu @logout="$emit('logout')"></sidebar-menu>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>
<script>
    import umsMixin from '../mixins/UMS';
    import SidebarMenu from './SidebarMenu.vue'

    export default {
        name: 'Sidebar',
        mixins    : [umsMixin],
        components: { SidebarMenu }
    }
</script>