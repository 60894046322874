<template>
    <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <nav-link route="/" icon="fa-home" :text="t('menu.dashboard')" :exact="true"></nav-link>
            <nav-parent icon="fa-user" :text="t('menu.account')" >
                <nav-link route="/account" icon="fa-id-card" :text="t('menu.account_overview')"
                          :exact="true"></nav-link>
                <nav-link route="/account/settings" icon="fa-cog" :text="t('menu.account_settings')"
                          v-if="user.active"></nav-link>
                <nav-link route="/account/password" icon="fa-key" :text="t('menu.account_password')"></nav-link>
                <nav-link route="/account/agreements" icon="fa-check" :text="t('menu.account_agreements')"></nav-link>
                <nav-link route="/account/reset-mail" icon="fa-medkit" :text="t('menu.account_reset_mail')"
                          v-if="user.active"></nav-link>
                <nav-link route="/account/user-cert" icon="fa-certificate" :text="t('menu.account_user_cert')"
                          v-if="user.active"></nav-link>
                <nav-link :route="{name: 'account.extend'}" icon="fa-sync-alt" :text="t('menu.account_extension')"
                          v-if="(user.guest || user.restricted) && user.active"></nav-link>
                <nav-link :route="{name: 'account.delete'}" icon="fa-trash" :text="t('menu.account_delete')"
                          v-if="user.guest || user.restricted"></nav-link>
            </nav-parent>
            <nav-parent v-if="user.active" icon="fa-cubes" :text="t('menu.resources')">
                <nav-link route="/resources/overview" icon="fa-th-list" :text="t('menu.resources_overview')"></nav-link>
                <nav-link route="/resources/create" icon="fa-plus-square" :text="t('menu.request')"></nav-link>
                <nav-link route="/resources/confirm" icon="fa-check-square" :text="t('menu.confirm')"></nav-link>
                <li class="nav-header">{{ t('resource.active_resources') }}</li>
                <!-- resource categories -->
                <nav-parent v-for="label in orderBy(sidebar, 'label')" :icon="label.icon" :text="label.label" :key="label.label">
                    <!-- resource types -->
                    <nav-link :route="'/resources/' + type.key + '/list'"
                              :text="type.description" :icon="type.icon" v-if="type.visible"
                              v-for="type in orderBy(label.children, 'description')" :key="type.key" exact>
                    </nav-link>
                </nav-parent>
            </nav-parent>
          <nav-link route="/accounts/confirm" icon="fa-users" :text="t('menu.accounts_check')"></nav-link>

            <li class="nav-header" v-if="(user.acl.admin && user.active) || user.acl.identification_admin">{{ 'Administration' | upper }}</li>
            <nav-link v-if="user.acl.identification_admin && uniIntern" route="/account/identification" icon="fa-address-card" :text="t('menu.identification')"></nav-link>
            <nav-parent v-if="user.acl.admin" icon="fa-pen-square" :text="t('menu.manage_data')"  >
                <nav-link route="/admin/accounts" icon="fa-users" :text="t('menu.user_accounts')"
                          v-if="user.acl.ldap_admin || user.acl.user_admin || user.acl.pwd_admin || user.acl.pwd_stud_admin || user.acl.pwd_med_admin"></nav-link>
                <nav-link :route="{name: 'admin.resources'}" icon="fa-cubes" :text="t('menu.manage_resources')"
                          v-if="user.acl.resource_admin || user.acl.resource_manager"></nav-link>
                <nav-link :route="{name: 'admin.resource-mail'}" icon="fa-envelope"
                          :text="t('menu.resource_mail')"
                          v-if="user.acl.ldap_admin || user.acl.resource_admin || user.acl.resource_manager"></nav-link>
            </nav-parent>
            <nav-link v-if="user.acl.ldap_admin" :route="{name: 'admin.account_applications'}"
                      icon="fa-users" :text="t('menu.account_applications')">
            </nav-link>
            <nav-link v-if="user.acl.ldap_admin || user.acl.user_admin || user.acl.resource_admin || user.acl.resource_manager"
                      :route="{name: 'admin.requests'}" icon="fa-inbox" :text="t('menu.edit_applications')">
            </nav-link>
            <nav-link v-if="user.acl.ldap_admin" :route="{name: 'admin.account-exchange'}" icon="fa-random" text="Account-Exchange"></nav-link>
            <nav-link v-if="user.acl.ldap_admin" route="/admin/files" icon="fa-copy" :text="t('menu.files')"></nav-link>
            <nav-link v-if="user.acl.language_admin" route="/admin/editor" icon="fa-pencil-alt" text="Editor"></nav-link>

            <li>
                <hr>
            </li>
            <li class="nav-item">
                <a href="#" @click.prevent="$emit('logout')" class="nav-link">
                    <i class="fas fa-fw fa-sign-out-alt"></i>
                    <p> {{ t('menu.logout') }} </p>
                </a>
            </li>
        </ul>
    </nav>
</template>
<script>
    import umsMixin from '../mixins/UMS';
    import arrayHelper from '../mixins/ArrayHelper';
    import NavigationLink from './ui/NavigationLink.vue';
    import NavigationParent from './ui/NavigationParent.vue';

    export default {
        mounted(){
            this.initTreeViewWorkAround();
            this.isUniIntern();
        },
        data(){
            return {
                uniIntern: false,
            };
        },
        methods : {
            initTreeViewWorkAround() {
                // Allerdings ist das Initialisieren des Treeviews ist beim Refresh und beim 1. Login und beim 1. Login->Logout->Login notwendig
                // Beim mehrmaligem Login->Logout->Login allerdings nicht!!!
                // Es bedarf eines weiteren WorkAround siehe workAroundCorrectClosingMenu in NavigationParent.vue
                // auskommentierte Logs bleiben bitte zu schnelleren Fehlerbehandlung stehen!

                var attribute = $("body").attr("loading");
//                console.log("attribute: " + attribute);
                if(typeof attribute === 'undefined' || attribute === false){
                    // Erstes Login sowie Refresh
                    $("body").attr("loading", "1");
                    $('[data-widget="treeview"]').Treeview('init');
//                    console.log("init Treeview...");
                } else if (attribute == 1) {
                    // Einmaliges Login -> Logout -> Login (das erste Mal)
                    $("body").attr("loading", "2");
                    $('[data-widget="treeview"]').Treeview('init');
//                    console.log("init Treeview...");
                } else {
                    // Mehrmaliges Login -> Logout -> Login
//                    console.log("kein init, loading = 2");
                }
            },
            isUniIntern(){
                this.$store.dispatch('account/isUniIntern', {})
                    .then((response) => {
                        if (response.data.uniIntern === 1) {
                            this.uniIntern = true;
                        }
                    });

            },
        },
        name      : 'SidebarName',
        mixins    : [arrayHelper, umsMixin],
        components: {
            'nav-link': NavigationLink,
            'nav-parent': NavigationParent,
        },
        computed  : {
            sidebar(){
                return this.UMS.config('sidebar');
            },

        }
    }
</script>

<style scoped>
    hr {
        border-color: #C2C7D0;
    }

    .nav-link > p{
        margin-left: 6px;
        white-space: normal;
    }
</style>