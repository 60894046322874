import store from "../store";

export function AdminGuard(types = []) {
    return (to, from, next) => {
        store.dispatch('account/loadAccount')
            .then(() => {
                let account = store.getters['account/account'];
                let specificAdminMatch = !types.length;

                if (!account.acl.admin) {
                    return next({path: '/'});
                }

                for(let type in types){
                    if(account.acl[types[type]]){
                        specificAdminMatch = true;
                        break;
                    }
                }

                if(specificAdminMatch){
                    return next();
                }

                next({path: '/'});
            })
            .catch(() => next({path: '/'}));
    }
}

export function AuthGuard(to, from, next) {
    if (!window.UMS.authenticated) {
        console.log('Not authenticated');
        let destination = {path: '/login'};
        if (to.fullPath !== '/') {
            destination.query = {redirect: to.fullPath};
        }
        return next(destination);
    }
    next();
}

export function GuestGuard(to, from, next) {
    if (window.UMS.authenticated) {
        console.log('Authenticated but Guest wanted');
        return next('/');
    }
    next();
}