<template>
    <div :class="['card card-info']">
        <div class="card-header" :class="{collapsable: collapseByHeader}"
             @click="collapseByHeader && collapse()">
            <slot name="header">
                <i v-if="icon" :class="['card-title mt-2 fas fa-fw', fullIcon]"></i>
                <div class="card-title mt-1"> {{ title }} </div>
                <div class="card-tools float-right">
                    <slot name="tools">
                        <button v-if="collapsable" type="button" class="btn"
                                @click.native="collapse">
                            <i class="fa" :class="collapseIcon"></i>
                        </button>
                        <button v-if="refreshable" type="button" class="btn" @click.stop="$emit('refresh')">
                            <i class="fas fa-sync-alt"></i>
                        </button>
                    </slot>
                </div>
            </slot>
        </div>
        <transition name="body">
            <div class="card-body" v-if="!collapsed">
                <slot></slot>
            </div>
        </transition>
        <transition name="footer">
            <div v-if="hasFooterSlot() && !collapsed" class="card-footer">
                <slot name="footer"></slot>
            </div>
        </transition>
        <loading-overlay></loading-overlay>
    </div>
</template>
<script>
    import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
    export default {
        components: {LoadingOverlay},
        props   : {
            type            : {type: String, default: 'default'},
            icon            : String,
            title           : String,
            refreshable     : Boolean,
            collapsable     : Boolean,
            collapseByHeader: Boolean,
            value           : Boolean
        },
        data(){
            return {
                collapsed: this.collapsable ? this.value : false
            }
        },
        computed: {
            fullIcon(){
                let icon = this.icon;
                if (!/^fa-/.test(icon)) {
                    icon = `fa-${icon}`
                }

                return icon;
            },
            collapseIcon(){
                return this.collapsed ? 'fa-plus' : 'fa-minus';
            }

        },
        methods : {
            collapse(){
                this.collapsed = !this.collapsed;
                this.$emit('input', this.collapsed);
            },
            hasFooterSlot(){
                return !!this.$slots.footer;
            },
        },
        watch   : {
            value(value){
                if (!this.collapsable) return;
                this.collapsed = value;
            }
        }
    }
</script>
<style>
    .card-header.collapsable{
        cursor: pointer;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .35s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }

    .collapse-enter-active, .collapse-leave-active {
        transition: all .3s;
    }

    .collapse-enter, .collapse-leave-to {
        overflow: hidden;
        opacity: 0;
        height: 0;
        padding: 0;
    }

    i.card-title{
        margin-right: 0.5rem;
    }
</style>