<template>
    <transition name="fade">
        <div class="global-overlay" v-if="loading">
            <div class="loading-spinner">
                <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
    </transition>
</template>
<script>
    import {mapGetters} from 'vuex';
    export default {
        computed: {
            ...mapGetters(['loading'])
        },
    }
</script>
<style>
    .global-overlay {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.7);
    }

    .global-overlay > .loading-spinner {
        position: absolute;
        top: 33%;
        left: 50%;
        font-size: 200%;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .35s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }
</style>