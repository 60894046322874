<template>
    <div role="dialog"
         :class="{ 'modal':true, 'fade':effect === 'fade' }">
        <div :class="{'modal-dialog':true,'modal-lg':large,'modal-sm':small, [optionalType]:true}" role="document"
             :style="{width: optionalWidth}">
            <div class="modal-content">
                <div class="modal-header">
                    <slot name="header">
                        <button type="button" class="close" @click="close">
                            <span>×</span>
                        </button>
                        <h5 class="modal-title">
                            <slot name="title">
                                {{title}}
                            </slot>
                        </h5>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button type="button" class="btn btn-secondary" @click="close" v-show="!hideCancelButton">
                            {{ cancelText }}
                        </button>
                        <button type="button" class="btn" :class="okClass" @click="$emit('ok')">{{ okText }}
                        </button>
                    </slot>
                </div>
            </div>
            <transition name="fade">
                <div class="overlay" v-if="loading">
                    <i class="fas fa-sync-alt fa-spin"></i>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    let $ = window.$;

    export default {
        props   : {
            okText          : {
                type   : String,
                default: '_Save changes'
            },
            okClass         : {
                type   : String,
                default: 'btn-primary'
            },
            hideCancelButton: {
                type   : Boolean,
                default: false
            },
            cancelText      : {
                type   : String,
                default: '_Close'
            },
            closeByKeyboard : {
                type   : Boolean,
                default: false
            },
            title           : {
                type   : String,
                default: ''
            },
            value           : {
                required: true,
                type    : Boolean,
            },
            width           : {
                default: null
            },
            effect          : {
                type   : String,
                default: 'fade'
            },
            backdrop        : {
                type   : Boolean,
                default: true
            },
            large           : {
                type   : Boolean,
                default: false
            },
            small           : {
                type   : Boolean,
                default: false
            },
            type            : {
                type   : String,
                default: ''
            }
        },
        computed: {
            ...mapGetters(['loading']),
            optionalType(){
                if (this.type) {
                    return `modal-${this.type}`
                }
                return '';
            },
            optionalWidth () {
                if (this.width === null) {
                    return null
                } else if (Number.isInteger(this.width)) {
                    return this.width + 'px'
                }
                return this.width
            }
        },
        mounted(){
            if (!this.closeByKeyboard) {
                return;
            }
            window.addEventListener('keyup', event => {
                if (event.keyCode === 27) {
                    this.close();
                }
            });
        },
        destroyed(){
            if(this.value){
                this.handleClose();
            }
        },
        watch   : {
            value (val) {
                const el = this.$el;
                const body = document.body;
                const scrollBarWidth = this.getScrollBarWidth();
                if (val) { // open
                    $(el).find('.modal-content').focus();
                    el.style.display = 'block';
                    setTimeout(() => $(el).addClass('in'), 0);
                    $(body).addClass('modal-open');
                    if (scrollBarWidth !== 0) {
                        body.style.paddingRight = scrollBarWidth + 'px';
                    }
                    if (this.backdrop) {
                        $(el).on('click', e => {
                            if (e.target === el) this.close();
                        })
                    }
                } else { // close
                    this.handleClose();
                }
            }
        },
        methods : {
            getScrollBarWidth () {
                if (document.documentElement.scrollHeight <= document.documentElement.clientHeight) {
                    return 0
                }
                let inner = document.createElement('p');
                inner.style.width = '100%';
                inner.style.height = '200px';

                let outer = document.createElement('div');
                outer.style.position = 'absolute';
                outer.style.top = '0px';
                outer.style.left = '0px';
                outer.style.visibility = 'hidden';
                outer.style.width = '200px';
                outer.style.height = '150px';
                outer.style.overflow = 'hidden';
                outer.appendChild(inner);

                document.body.appendChild(outer);
                let w1 = inner.offsetWidth;
                outer.style.overflow = 'scroll';
                let w2 = inner.offsetWidth;
                if (w1 === w2) w2 = outer.clientWidth;

                document.body.removeChild(outer);

                return (w1 - w2);
            },
            close () {
                this.$emit('input', false);
                this.$emit('close', false);
            },
            handleClose(){
                const el = this.$el;
                const body = document.body;

                body.style.paddingRight = null;
                $(body).removeClass('modal-open');
                $(el).removeClass('in').on('transitionend', () => {
                    $(el).off('click transitionend');
                    el.style.display = 'none';
                })
            }
        }
    }
</script>
<style>
    .modal {
        transition: all 0.3s ease;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .35s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }

    .modal-dialog > .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, .7);
    }

    .modal-dialog > .overlay > .fa {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        color: #000;
        font-size: 30px;
    }

    .modal.in {
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>