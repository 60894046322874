<template>
    <router-link :to="route" tag="li" class="nav-item" :exact="exact" :isactive="isactive"
                 :event="dynamicEvent" @click.native="clickMenuItem">
        <a :class="['nav-link', {'active': isactive }]">
            <i :class="['nav-icon fas fa-fw', icon, textColor]"></i>
            <p>
                {{ text }}
                <template v-if="isTree"> <!-- template tag for using if -->
                    <i class="right fas fa-angle-left"></i>
                </template>
            </p>
        </a>
        <ul v-if="isTree" class="nav nav-treeview autoHeight">
            <slot></slot>
        </ul>
    </router-link>
</template>
<script>
    export default {
        props   : {
            route: {type: [String, Object]},
            icon: String,
            text: String,
            color: String,
            isactive: Boolean,
            label: String,
            exact: Boolean,
            event: {type: String, default: 'click'}
        },
        computed: {
            bgColor(){
                if (!this.color) {
                    return '';
                }
                return `bg-${this.color}`;
            },
            textColor(){
                if (!this.color) {
                    return '';
                }
                return `text-${this.color}`;
            },
            isTree(){
                return !!this.$slots.default;
            },
            dynamicEvent(){
                return this.isTree ? 'none' : this.event;
            }
        },
        methods : {
            clickMenuItem() {
                this.settingActiveClass();
            },
            settingActiveClass() {
//                console.log("NavLink: settingActiveClass");
                $(document).click(function(event) { // aktuelles Element auf das geklickt wurde
//                    console.log("aktuelles Element: " + $(event.target).text() + "Zeit: " + Date.now());
                    $('a').removeClass('active');
                    $(event.target).addClass("active"); // falls neben den Menutext geklickt wird
                    $(event.target).parent('a').addClass("active");
                    $(event.target).parents('ul').siblings('a').addClass("active");
                });
            }
        }
    }
</script>

<style scoped>
    .nav-link{
        display: flex !important;
        align-items: baseline;
        justify-content: flex-start;
    }

    .nav-link > p{
        margin-left: 6px;
        white-space: normal;
    }

    .autoHeight {
        /* Workaround For Adminlte3-1 Vuejs2-6 */
        height: auto !important;
    }
</style>