import initialStates from "./initial-states";

export default {
    namespaced: true,
    state     : {
        dict: initialStates.language.dict()
    },
    mutations : {
        CLEAR_ALL(state){
            state.dict = initialStates.language.dict();
        },
        add(state, data) {
            state.dict = Object.assign({}, state.dict, data);
            // state.dict = data;
        }
    },
    actions   : {
        load({state, commit}){
            return window.UMS.api.get('language', 'get')
                .then(response => commit('add', response.data));
        },
        change({dispatch}, {language}){
            return window.UMS.api.post('language', 'change', {language})
                .then(() => dispatch('load'));
        },
    },
    getters   : {
        dict(state){
            return state.dict;
        },
        // TODO KB remove logs language getters dict
        translate(state){

            return (languageKey, params) => {
                let debug = window.UMS.config('debug');
                let [type, key] = languageKey.split('.');
                // console.log('type= ' + JSON.stringify(type, null, 2));
                // console.log('key= ' + JSON.stringify(key, null, 2));
                let dict = state.dict[type] ? state.dict[type] : {};
                // console.log('dict= ' + JSON.stringify(dict, null, 2));

                let string = dict[key];
                string = string ? string : debug ? `Untranslated: ${languageKey}` : '';
                for (let param in params) {
                    string = string.replace(new RegExp('%' + param, 'g'), params[param]);
                }
                // return string.replace(/<\/?[^>]+(>|$)/g, "");
                return string;
            }
        },
    }
};