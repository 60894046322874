import Vue from "vue";
import Vuex from "vuex";

import AdminStore from "./admin";
import AccountStore from "./account";
import BaseStore from "./base";
import EditorStore from "./editor";
import LanguageStore from "./language";
import ResourcesStore from "./resources";

// const EditorStore = r => require.ensure([], () => r(require('./editor')), 'admin');

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        admin    : AdminStore,
        account  : AccountStore,
        base     : BaseStore,
        editor   : EditorStore,
        language : LanguageStore,
        resources: ResourcesStore,
    },
});

