<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <!--Info-->
        <card :title="t('account.start_headline')" icon="info">
          {{ t('account.start_text') }}
        </card>

        <!--Schnellstart-->
        <card :title="t('menu.quick_start')" icon="rocket">
          <div class="row">
            <div class="col-sm-4">
              <h5><i class="fas fa-user"></i> {{ t('menu.account') }}</h5>
              <div class="mb-3">
                <router-link :to="{name: 'account.overview'}">
                  <i class="fas fa-id-card"></i> {{ t('menu.account_overview') }}
                </router-link>
                {{ t('status.account_summary') }}
              </div>
              <div class="mb-3">
                <router-link :to="{name: 'account.settings'}">
                  <i class="fas fa-cog"></i> {{ t('menu.account_settings') }}
                </router-link>
                {{ t('status.account_settings_summary') }}
              </div>
            </div>

            <div class="col-sm-4">
              <h5><i class="fas fa-cubes"></i> {{ t('menu.resources') }}</h5>
              <div class="mb-3">
                <router-link :to="{name: 'resource.overview'}">
                  <i class="fas fa-th-list"></i> {{ t('menu.resources_overview') }}
                </router-link>
                {{ t('status.resource_summary') }}
              </div>
              <div class="mb-3">
                <router-link :to="{name: 'resource.create'}">
                  <i class="fas fa-plus-square"></i> {{ t('menu.request') }}
                </router-link>
                {{ t('status.create_resource_summary') }}
              </div>
            </div>

            <div class="col-sm-4">
              <h5><i class="fas fa-briefcase"></i> {{ t('menu.miscellaneous') }}</h5>
              <div class="mb-3" v-if="typeByKey('netaccesscontrol')">
                <router-link :to="{name: 'resource.list', params: {type:'netaccesscontrol'}}">
                  <i class="fa" :class="typeByKey('netaccesscontrol').icon"></i>
                  {{ t('menu.registered_wired_devices') }}
                </router-link>
                {{ t('status.nac_summary') }}
              </div>
              <div class="mb-3">
                <router-link :to="{name: 'resource.list', params: {type:'functional_account'}}">
                  <i class="fas fa-user-secret"></i> {{ t('menu.functional') }}
                </router-link>
                {{ t('status.functional_account_summary') }}
              </div>
            </div>

          </div>
        </card>

        <!--Energiebonus-->
        <form v-if="displayEnergyBonus" @submit.prevent="retrieveEnergyBonusPin()">
            <card :title="t('account.energybonus')" icon="info">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group mr-2">
                            <label for="pin">PIN</label>
                            <input type="text" id="pin" :value="pin"
                                   class="form-control" readonly>
                        </div>
                    </div>
                    <div class="callout col-md-4">
                        <p class="text-muted"> {{ t('account.energybonus_text') }} </p>
                    </div>
                </div>
                <div slot="footer">
                    <div class="row">
                        <div class="col-md-4">
                            <input type="submit" :value="t('account.energybonus_pin_retrieve')" 
                                   class="btn bg-danger w-100 mr-2">
                        </div>

                    </div>
    
                </div>
            </card>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <!--Kurzübersicht-->
        <card :title="t('account.personal_info_short')" icon="fa-id-card">
          <div class="row">
            <div class="col-md-4">
              <img class="profile-user-img img-fluid" :src="avatar">
            </div>
            <div class="col">
              <dl>
                <div class="row">
                  <dt class="col-md-4">{{ t('status.account_status') }}</dt>
                  <dd class="col">
                    <span v-if="user.active" class="badge badge-success">
                      {{ t('status.account_active') }} <i class="fas fa-check"></i>
                    </span>
                    <span v-else class="badge badge-danger">
                      {{ t('status.account_inactive') }} <i class="fas fa-exclamation"></i>
                    </span>
                  </dd>
                </div>

                <div class="row">
                  <dt class="col-md-4">{{ t('status.username') }}</dt>
                  <dd class="col">{{ user.uid }}</dd>
                </div>
              </dl>
              <dl>
                <div class="row">
                  <dt class="col-md-4">{{ t('form.name') }}</dt>
                  <dd class="col">{{ user.cn }}</dd>
                </div>
                <div class="row">
                  <dt v-if="user.primaryPhone" class="col-md-4">{{ t('status.primary_phone') }}</dt>
                  <dd v-if="user.primaryPhone" class="col">{{ user.primaryPhone }}</dd>
                </div>
                <div class="row">
                  <dt class="col-md-4">{{ t('status.primary_mail') }}</dt>
                  <dd class="col">{{ user.primaryMail }}</dd>
                </div>
              </dl>
              <dl>
                <div class="row">
                  <dt class="col-md-4">{{ t('menu.account_reset_mail') }}</dt>
                  <dd v-if="resetMail" class="col">
                    {{ user.resetMail.mail }}
                    <div v-if="user.resetMail.status == 'notconfirmed'">
                      <span class="badge badge-warning">
                        {{ t('account.reset_mail_unconfirmed') }}
                        <i class="fas fa-exclamation"></i>
                      </span>
                    </div>
                  </dd>
                  <dd v-else class="col">
                    <span class="badge badge-warning">
                      {{ t('general.missing') }}
                      <i class="fas fa-exclamation"></i>
                    </span>
                  </dd>
                </div>
                <div class="row">
                  <dt v-if="user.eov" class="col-md-4">{{ t('status.eov') }}</dt>
                  <dd v-if="user.eov" class="col">{{ user.eov | date }}</dd>
                </div>
              </dl>
              <div class="row">
                <div v-if="!resetMail || user.resetMail.status == 'notconfirmed'" class="col-md-4 mt-3">
                  <router-link :to="{name: 'account.reset-mail'}" class="btn btn-secondary btn-block">
                    {{ t('account.edit_reset_mail') }}
                  </router-link>
                </div>
                <div class="col-md-4 mt-3">
                  <router-link :to="{name: 'account.settings'}" class="btn btn-secondary btn-block">
                    {{ t('account.edit_profile') }}
                  </router-link>
                </div>
                <div class="col-md-4 mt-3">
                  <router-link :to="{name: 'account.overview'}" class="btn btn-secondary btn-block">
                    {{ t('account.overview_open') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <!-- Offene Einwilligungen -->
      <div v-if="openAgreements.length" class="col-md-4">
        <card :title="t('agreement.open_agreements')" icon="check">
          <ul>
            <!--agreement.label contains the phrasebook-key which has to be cached in a variable via :set-->
            <li v-for="agreement in openAgreements" :set="label_phrasebook = agreement.label">
              <router-link :to="{name: 'account.agreements'}" v-html="t(label_phrasebook)"></router-link>
            </li>
          </ul>
        </card>
      </div>

      <!-- Offene Antragsgenehmigungen -->
      <div class="col-md-4" v-if="status.ceo">
        <card :title="t('resource.open_ceo_requests')" icon="check-square">
          <p v-html="t('status.ceo_open', { count: status.ceo})"></p>
          <router-link :to="{name: 'resource.confirm'}" class="btn btn-secondary btn-block">
            {{ t('status.request_overview_button_text') }}
          </router-link>
        </card>
      </div>
      <div class="col-md-4" v-if="user.acl.admin && status.requests">
        <card :title="t('status.open_requests')" icon="inbox">
          <p v-html="t('status.requests_open', { count: status.requests})"></p>
          <router-link :to="{name: 'admin.requests'}" class="btn btn-secondary btn-block">
            {{ t('status.request_overview_button_text') }}
          </router-link>
        </card>
      </div>
    </div>
  </div>

</template>

<script>
import umsMixin from '../../mixins/UMS';
import {mapGetters} from "vuex";

export default {
  mixins: [umsMixin],
//        Eine Überschrift wird nicht benötigt für Startseite/Dashboard
//        component: {
//            name: 'menu.dashboard',
//        },
  computed: {
    ...mapGetters("account", ["agreements"]),
    ...mapGetters(["status"]),
    active() {
      return this.user.active === 'TRUE';
    },
    resetMail() {
      return this.user.resetMail && this.user.resetMail.mail;
    },
    openAgreements() {
      return this.agreements.filter(agreement => !agreement.status);
    },
  },
  mounted() {
    this.$store.dispatch("account/loadAgreements");
    this.shouldDisplayEnergyBonusButton();
  },
    data() {
      return {
        displayEnergyBonus: false,
        pin: '',
      };
    },
  methods: {
    shouldDisplayEnergyBonusButton() {
      this.UMS.api.get('account', 'shouldDisplayEnergyBonus')
        .then(response => {
          this.displayEnergyBonus = response.data.displayEnergyBonus;
        });
    },
    retrieveEnergyBonusPin() {
      this.UMS.api.get('account', 'retrieveEnergyBonusPin')
        .then(response => {
            this.pin = response.data.pin;
        });
    },
  },
}
</script>

<style scoped>
.profile-user-img {
  width: 200px;
}
</style>