import initialStates from "./initial-states";

export default {
    namespaced: true,
    state     : {
        account       : initialStates.account.account(),
        accountPromise: initialStates.account.accountPromise(),
        agreements    : initialStates.account.agreements(),
    },
    mutations : {
        CLEAR_ALL(state){
            state.account = initialStates.account.account();
            state.accountPromise = initialStates.account.accountPromise();
            state.agreements = initialStates.account.agreements();
        },
        setAccount(state, payload){
            state.account = payload;
        },
        setAccountPromise(state, payload){
            state.accountPromise = payload;
        },
        setAgreements(state, payload){
            state.agreements = payload.agreements;
        },
    },
    actions   : {
        login({commit, dispatch}, {uid, password, language}){
            return new Promise((resolve, reject) => {
                window.UMS.api.post('auth', 'login', {uid, password, language})
                    .then(response => {
                        commit('CLEAR_ALL');
                        commit('CLEAR_ALL', null, {root: true});
                        commit('admin/CLEAR_ALL', null, {root: true});
                        commit('editor/CLEAR_ALL', null, {root: true});
                        commit('resources/CLEAR_ALL', null, {root: true});

                        window.UMS.api.setCsrfToken(response.data.csrf_token);
                        window.UMS.authenticated = true;
                        dispatch('language/load', null, {root: true});
                        dispatch('loadAccount', true);
                        resolve(response);
                    })
                    .catch(error => reject(error))
            });
        },
        logout({commit, dispatch}){
            return new Promise((resolve, reject) => {
                window.UMS.api.post('auth', 'logout')
                    .then(response => {
                        window.UMS.api.setCsrfToken(response.data.csrf_token);
                        dispatch('logoutCleanUp');
                        resolve(response);
                    })
                    .catch(error => reject(error))
            });
        },
        logoutCleanUp({commit}){
            window.UMS.authenticated = false;
            window.UMS.api.get('app', 'csrf').then(response => {
                window.UMS.api.setCsrfToken(response.data.csrf_token);
            });
            commit('CLEAR_ALL');
            commit('CLEAR_ALL', null, {root: true});
            commit('admin/CLEAR_ALL', null, {root: true});
            commit('editor/CLEAR_ALL', null, {root: true});
            commit('resources/CLEAR_ALL', null, {root: true});
        },
        loadAccount({state, commit}, force = false){
            if (!force && state.accountPromise) {
                return state.accountPromise;
            }
            let promise = window.UMS.api.get('account', 'info')
                .then(({data}) => commit('setAccount', data));
            commit('setAccountPromise', promise);
            return promise;
        },
        changePrimaryMail({commit}, {primary}){
            return window.UMS.api.post('account', 'change-primary-mail', {primary})
                .then(({data}) => commit('setAccount', data.account));
        },
        createUserCert({commit}, {csr}){
            return window.UMS.api.post('account', 'create-user-cert', {csr});
        },
        createUserCertWithToken({commit}, {csr, user_token}){
            return window.UMS.api.post('account', 'createUserCertWithToken', {csr, user_token});
        },
        readMyCerts(){
            return window.UMS.api.post('account', 'readMyCerts', {});
        },
        confirmAccountList(){
            return window.UMS.api.post('account', 'confirmAccountList', {});
        },
        showConfirmForm({commit}, {order_number}){
            return window.UMS.api.get('account', 'showConfirmForm', {order_number});
        },
        rejectAccountRequest({commit}, {order_number}){
            return window.UMS.api.get('account', 'rejectAccountRequest', {order_number});
        },
        approveAccountRequest({commit}, {order_number}){
            return window.UMS.api.get('account', 'approveAccountRequest', {order_number});
        },
        loadAgreements({commit}, payload){
            return window.UMS.api.get('agreement', 'list', {type: payload})
                .then(response => commit('setAgreements', response.data));
        },
        agreeAgreement({commit}, {agreement, agb}){
            return window.UMS.api.post('agreement', 'agree', {agreement, agb})
                .then(response => commit('setAgreements', response.data));
        },
        revokeAgreement({commit}, {agreement}){
            return window.UMS.api.post('agreement', 'revoke', {agreement})
                .then(response => commit('setAgreements', response.data));
        },
        setResetMail({commit}, reset_mail){
            return window.UMS.api.post('account', 'set-reset-mail', {reset_mail})
                .then(({data}) => commit('setAccount', data.account));
        },
        setIdentificationForAccount({commit}, data){
            return window.UMS.api.post('account', 'set-identification-for-account', data);
        },
        searchUserForIdentification({commit}, data){
            return window.UMS.api.post('account', 'search-user-for-identification', data);
        },
        isUniIntern({commit}){
            return window.UMS.api.post('account', 'isUniIntern', {});
        },
        isCreateAndReadCertsAllowed({commit}){
            return window.UMS.api.post('account', 'isCreateAndReadCertsAllowed', {});
        },
        resendResetMailConfirmationMail(){
            return window.UMS.api.post('account', 'resend-reset-mail-confirmation-mail');
        },
        removeResetMail({commit}){
            return window.UMS.api.post('account', 'remove-reset-mail')
                .then(({data}) => commit('setAccount', data.account));
        },
        setAvatar({commit}, {avatar, agb}){
            return window.UMS.api.post('account', 'set-avatar', {avatar, agb})
                .then(({data}) => commit('setAccount', data.account));
        },
        removeAvatar({commit}){
            return window.UMS.api.post('account', 'remove-avatar')
                .then(({data}) => commit('setAccount', data.account));
        },
        confirmResetMail({commit}, {uid, token}){
            return window.UMS.api.post('password', 'confirm-reset-mail', {uid, token});
        },
        changePersonalPassword({commit}, {current_password, new_password, new_password2}){
            return window.UMS.api.post('password', 'change-personal-password', {
                current_password,
                new_password,
                new_password2
            });
        },
        changeDcPassword({commit}, {current_dc_password}){
            return window.UMS.api.post('password', 'change-dc-password', {current_dc_password} );
        },
        changeDcPasswordForFunctionalAccount({commit}, {current_dc_password, functional_account}){
            return window.UMS.api.post('password', 'change-dc-password-functional-account', {
                current_dc_password,
                functional_account
            } );
        },
        setFunctionalAccount({commit}, {uid, new_password, new_password2}){
            return window.UMS.api.post('password', 'set-functional-password', {
                uid,
                new_password,
                new_password2
            });
        },
    },
    getters   : {
        account(state){
            return state.account;
        },
        agreements(state){
            return state.agreements;
        },
    }
};