<template>
    <div class="login-page">
        <guest-view class="login-box">
            <div class="login-box-body">
                <h2 class="text-center text-danger"><i class="fas fa-exclamation-circle"></i> {{t('general.error_title')}}</h2>
                <p>{{t('general.error_not_found')}}</p>
                <router-link :to="{name: 'status'}">{{t('general.return_to_homepage')}}</router-link>
            </div>
        </guest-view>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import umsMixin from "../mixins/UMS";
    import GuestView from './GuestView.vue';
    export default {        
        components: {GuestView},
        mixins: [umsMixin],
    }
</script>