import moment from "moment";

export function count(array) {
    return array ? array.length : 0;
}

export function int(string) {
    return parseInt(string);
}

export function upper(string) {
    return string.toUpperCase();
}

export function lower(string) {
    return string.toLowerCase();
}

export function ucfirst(string) {
    return string[0].toUpperCase() + string.slice(1);
}

export function date(string) {
    return moment(string).format('DD.MM.YYYY');
}

export function datetime(string) {
    return moment(string).format('DD.MM.YYYY HH:mm');
}

export function json(string) {
    return JSON.stringify(string, null, 2);
}

export function ip(string) {
    let match = /(\d+)\.(\d+)\.(\d+)\.(\d+)/g.exec(string);
    if (!match) return '';
    return `${Number(match[1])}.${Number(match[2])}.${Number(match[3])}.${Number(match[4])}`;
}

export function mac(string) {
    let match = /([0-9a-f]{2}:?)+/ig.exec(string);
    if (!match) return '';
    return `${match[0]}`.toUpperCase();
}

export default {count, int, upper, lower, ucfirst, date, datetime, json, ip, mac};