<template>
    <div :class="['alert', `alert-${type}`, dismissClass]">
        <button type="button" class="close" v-if="dismissable">
            <i class="fas fa-times"></i>
        </button>
        <h5 v-if="title">{{ title }}</h5>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props   : {
            type : {type: String, default: 'info'},
            title: String,
            dismissable:{ type: Boolean, default: false}
        },
        computed: {
            dismissClass(){
                return this.dismissable ? 'alert-dismissable' : '';
            },
        }
    }
</script>
<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .35s
    }

    .fade-enter, .fade-leave-to
    {
        opacity: 0
    }
</style>