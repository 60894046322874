import initialStates from "./initial-states";

export default {
    namespaced: true,
    state     : {
        phrasebook: initialStates.editor.phrasebook()
    },
    mutations : {
        CLEAR_ALL(state){
            state.phrasebook = initialStates.editor.phrasebook();
        },
        set(state, payload){
            state.phrasebook = payload;
        }
    },
    actions   : {
        load({commit}){
            return window.UMS.api.get('language', 'phrasebook')
                .then(response => commit('set', response.data.phrasebook));
        },
        update({commit}, {key, en, de}){
            return window.UMS.api.post('language', 'update', {key, en, de})
                .then(response => commit('set', response.data.phrasebook));
        }
    },
    getters   : {
        de(state){
            return state.phrasebook.de;
        },
        en(state){
            return state.phrasebook.en;
        }

    }
};